import { Link } from '@remix-run/react';
import { Section } from '../components/section';
import { sections } from '../hooks/use-section-analytics';
import { useIntercom } from 'react-use-intercom';
import { useCallback } from 'react';
import { AmplitudeEvent, ButtonTag, analyticsState } from '../state/amplitude';
import { getLowestPricedTent, getPrice } from '../state/get-tent-price';
import { images } from '../constants/images';
import Sprite from '../sprites/sprite';
import { displayPrice } from '../state/prices';
import { FormattedString, useMessages } from '../i18n/useMessages';

export const JoinUsSection = () => {
  const { show } = useIntercom();

  const onClickChat = useCallback(() => {
    analyticsState?.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      buttonTag: ButtonTag.CHAT_WITH_US,
    });

    show();
  }, [show]);

  const onClickBook = useCallback(() => {
    analyticsState?.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      buttonTag: ButtonTag.BOOK_A_ROOM,
    });
  }, []);

  const lowestPrice = displayPrice({
    priceInINR: getPrice(getLowestPricedTent()),
    showCurrency: true,
  });

  const getMessage = useMessages();

  return (
    <Section className="w-full m-auto pt-36 md:pt-52" id={sections['joinUs']}>
      <div className="relative m-auto flex flex-col items-center justify-center">
        <span className="absolute left-1/2 -translate-x-1/2 -z-10 w-[99%] lg:w-[960px] md:w-11/12 md:h-96 h-80 sm:w-11/12">
          <img
            loading="lazy"
            src={images['large-circle-footer']}
            alt="Join the community."
            className="w-full h-full"
          />
          <Sprite
            name="kapow"
            width="126"
            height="110"
            className="absolute right-0 top-0 md:right-8 md:top-4"
          />
          <Sprite
            name="star"
            width="59"
            height="76"
            className="absolute left-2 bottom-4 md:left-8 md:bottom-8"
          />
        </span>
        <hgroup className="px-8 m-auto text-center">
          <h2 className="xl:text-xl text-lg font-sans uppercase text-red-200 tracking-widest font-bold">
            {getMessage('camp67-join-now.book-now')}
          </h2>
          <h3 className="flex flex-col sm:block font-serif xl:text-7xl md:text-6xl text-3xl font-semibold xl:leading-10 md:leading-9 leading-6 mt-2">
            <FormattedString message='camp67-join-now.interested-in-kumbh' components={{
              'join-us-text': <span className="text-red-200 h-0" />
            }} />
          </h3>
        </hgroup>
      </div>
      <div className="m-auto flex flex-row text-nowrap whitespace-nowrap px-10 flex-wrap items-center justify-center gap-6 md:gap-10 mt-32">
        <Link to="/packages/reservation">
          <button
            onClick={onClickBook}
            className="px-8 py-6 md:px-20 md:py-9 -skew-x-12 bg-red-200 text-white shadow-md-drop shadow-red-400 rounded-md border border-transparent hover:bg-red-300 transition-all duration-300 ease-in-out"
          >
            <p className="skew-x-12 uppercase tracking-wider">
              <FormattedString message='camp67-join-now.book-now-lowest-price' /> {lowestPrice}
            </p>
          </button>
        </Link>
        <button
          onClick={onClickChat}
          className="px-8 py-6 md:px-20 md:py-9 -skew-x-12 bg-white text-red-300 shadow-md-drop shadow-red-400 rounded-md border-solid border border-black  hover:shadow-red-100 transition-all duration-300 ease-in-out"
        >
          <p className="skew-x-12 uppercase tracking-wider">
            <FormattedString message='camp67-join-now.chat-with-us' />
          </p>
        </button>
      </div>
    </Section>
  );
};
