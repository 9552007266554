import React from 'react';

import { images } from '../../constants/images';
import Sprite from '../../sprites/sprite';
import { Tooltip } from '../tooltip';
import { MessageKey } from '../../locales/en';
import { useMessages } from '../../i18n/useMessages';

export interface TestimonialItem {
  imageSrc: string;
  imageAlt: MessageKey;
  quote: MessageKey;
  author: {
    name: string;
    location: MessageKey;
    videoSrc: string;
  };
}

export const testimonials: TestimonialItem[] = [
  {
    imageSrc: images['avatar-richard'],
    imageAlt: 'camp67-we-love-kumbh.richard-testimonial.image-alt',
    quote: 'camp67-we-love-kumbh.richard-testimonial.quote',
    author: {
      name: 'Richard Singh',
      location: 'camp67-we-love-kumbh.richard-testimonial.location',
      videoSrc: 'https://www.youtube.com/embed/wqfyTic9L7E?si=qcS-27EwZ2HZqSCi',
    },
  },
  {
    imageSrc: images['avatar-rajesh'],
    imageAlt: 'camp67-we-love-kumbh.rajesh-testimonial.image-alt',
    quote: 'camp67-we-love-kumbh.rajesh-testimonial.quote',
    author: {
      name: 'Rajesh Mittal',
      location: 'camp67-we-love-kumbh.rajesh-testimonial.location',
      videoSrc: 'https://www.youtube.com/embed/5tSohpCNSJ8?si=3GhJ68RwnNTQ3wdl',
    },
  },
  {
    imageSrc: images['avatar-gabriella'],
    imageAlt: 'camp67-we-love-kumbh.gabriella-testimonial.image-alt',
    quote: 'camp67-we-love-kumbh.gabriella-testimonial.quote',
    author: {
      name: 'Gabriella Burnel',
      location: 'camp67-we-love-kumbh.gabriella-testimonial.location',
      videoSrc: 'https://www.youtube.com/embed/LygBGodZrk4?si=XDkG7YWTnNl3UNjM',
    },
  },
  {
    imageSrc: images['avatar-pawars'],
    imageAlt: 'camp67-we-love-kumbh.pawars-testimonial.image-alt',
    quote: 'camp67-we-love-kumbh.pawars-testimonial.quote',
    author: {
      name: 'Amit & Swapna Pawar',
      location: 'camp67-we-love-kumbh.pawars-testimonial.location',
      videoSrc: 'https://www.youtube.com/embed/SeRjwcdlVFQ?si=31UUByi98xRs-0H8',
    },
  },
];

export const Testimonial: React.FC<TestimonialItem & { onClick: VoidFunction }> = ({
  author,
  imageAlt,
  imageSrc,
  quote,
  onClick,
}) => {
  const getMessage = useMessages();
  const alt = getMessage(imageAlt);
  const formattedQuote = getMessage(quote);
  const location = getMessage(author.location);

  return (
    <div className="flex flex-row items-start gap-3 w-fit">
      <div className="relative">
        <img
          loading="lazy"
          src={imageSrc}
          alt={alt}
          className="w-16 h-16 min-w-16 min-h-16 rounded-full select-none pointer-events-none object-cover"
        />
        <Tooltip
          text="View Testimonial"
          className="absolute right-0 bottom-0 font-mono text-xs"
          spacing="compact"
        >
          <Sprite
            name="play"
            width="64"
            height="64"
            className="w-6 h-6 cursor-pointer"
            onClick={onClick}
          />
        </Tooltip>
      </div>
      <div className="flex flex-col w-fit">
        <p className="max-w-[420px] text-sm">{formattedQuote}</p>
        <p className="uppercase mt-3 tracking-wider text-xs text-red-200 font-medium">
          {author.name}, {location}
        </p>
      </div>
    </div>
  );
};
