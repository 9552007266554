import React from 'react';

interface SectionProps {
  className: string;
  id: string;
  children: React.ReactNode;
}

export const Section: React.FC<SectionProps> = ({ className, id, children }) => {
  return (
    <section className={className} id={id}>
      {children}
    </section>
  );
};
