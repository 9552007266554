import { MessageKey } from '../locales/en';

export const headerTypewriteKeys: MessageKey[] = [
  'camp67-header.typewriter.serene',
  'camp67-header.typewriter.peaceful',
  'camp67-header.typewriter.safe',
  'camp67-header.typewriter.secure',
  'camp67-header.typewriter.hygienic',
  'camp67-header.typewriter.affordable',
];
export const footerTypewriterKeys = [
  'Join Us',
  'हमसे जुड़ें',
  'Unisciti a noi',
  'Únete a nosotros',
  'Rejoignez-nous',
  '加入我们',
];
