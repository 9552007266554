import { useEffect } from 'react';

import { typewriter } from '../services/typewriter';
import { AmplitudeEvent, analyticsState } from '../state/amplitude';
import { headerTypewriteKeys, footerTypewriterKeys } from '../constants/typewriter-keys';
import { propagateError } from '../propagate-error';
import { useMessages } from '../i18n/useMessages';

export const sections = {
  header: 'camp67-header',
  oneOfAKind: 'one-of-a-kind-section',
  weLoveKumbh: 'we-love-kumbh-section',
  galleryWelcome: 'gallery-welcome-section',
  locationGanges: 'location-ganges-section',
  accommodationAmenities: 'accommodation-amenities-section',
  experienceKumbh: 'experience-kumbh-section',
  joinUs: 'join-us-section',
} as const;

const sectionKeys = Object.values(sections);

type SectionKey = (typeof sections)[keyof typeof sections];

const sectionSource: Record<SectionKey, string> = {
  'camp67-header': 'Camp67 Header',
  'one-of-a-kind-section': 'One of a Kind',
  'we-love-kumbh-section': 'We Love Kumbh',
  'gallery-welcome-section': 'Gallery Welcome',
  'location-ganges-section': 'Location Ganges',
  'accommodation-amenities-section': 'Accommodation Amenities',
  'experience-kumbh-section': 'Experience Kumbh',
  'join-us-section': 'Join Us',
} as const;

const sectionSeen: Record<SectionKey, boolean> = {
  'camp67-header': false,
  'one-of-a-kind-section': false,
  'we-love-kumbh-section': false,
  'gallery-welcome-section': false,
  'location-ganges-section': false,
  'accommodation-amenities-section': false,
  'experience-kumbh-section': false,
  'join-us-section': false,
};

const onSectionReady = (key: SectionKey, getMessage: ReturnType<typeof useMessages>) => {
  if (!sectionSeen[key]) {
    analyticsState.amplitude?.track(AmplitudeEvent.SECTION_VIEW, {
      screenSource: sectionSource[key],
    });
    sectionSeen[key] = true;
  }

  if (key === sections['header']) {
    const keys = headerTypewriteKeys.map((key) => getMessage(key));
    typewriter.add('#header-typewriter', keys);
  }

  if (key === sections['joinUs']) {
    typewriter.add('#join-us-typewriter', footerTypewriterKeys);
  }
};

export const useInitializeSectionAnalytics = () => {
  const getMessage = useMessages();

  useEffect(() => {
    const options: IntersectionObserverInit = { rootMargin: '0px', threshold: 0.01 };

    const observer = new IntersectionObserver((entries) => {
      if (entries.length === 0) {
        return;
      }

      for (const entry of entries) {
        if (!entry.isIntersecting) {
          continue;
        }

        onSectionReady(entry.target.id as SectionKey, getMessage);
      }
    }, options);

    const observers: IntersectionObserver[] = [];

    for (const key of sectionKeys) {
      const target = document.querySelector<HTMLElement>(`#${key}`);
      if (!target) {
        propagateError(new Error('no target found for section'), 'section-analytics');

        continue;
      }

      observer.observe(target);
      observers.push(observer);
    }

    return () => {
      for (const observer of observers) {
        observer.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
