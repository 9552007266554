import { Link } from '@remix-run/react';
import Sprite from '../../sprites/sprite';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { PageRoutes } from '../../pages/page-routes';
import { AmplitudeEvent, ButtonTag, analyticsState } from '../../state/amplitude';
import { MessageKey } from '../../locales/en';
import { FormattedString, useMessages } from '../../i18n/useMessages';
import { Trans } from 'react-i18next';

export interface FAQItem {
  question: MessageKey;
  answer: React.ReactNode;
}

export const faqs: FAQItem[] = [
  {
    question: 'camp67-faq.private-suites',
    answer: (
      <FormattedString
        message='camp67-faq.private-suites.answer'
        components={{
          'twin-room-link': (
            <Link
              to={PageRoutes.twinRoom}
              prefetch="intent"
              className="underline text-blue-500"
            />
          ),
          'family-room-link': (
            <Link
              to={PageRoutes.familyRoom}
              prefetch="intent"
              className="underline text-blue-500"
            />
          ),
          'family-room-plus-link': (
            <Link
              to={PageRoutes.familyRoomPlus}
              prefetch="intent"
              className="underline text-blue-500"
            />
          ),
          'kumbh-sukrit-link': (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://kumbhsukrit.com"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            />
          ),
          paragraph: <p className="mt-3" />,
        }}
      />
    ),
  },
  {
    question: 'camp67-faq.camp-start',
    answer: (
      <FormattedString
        message='camp67-faq.camp-start.answer'
        components={{
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          'camp-start-link': <a href="#subscribe" className="underline text-blue-500" />,
        }}
      />
    ),
  },
  {
    question: 'camp67-faq.international-travel',
    answer: (
      <FormattedString
        message='camp67-faq.international-travel.answer'
        components={{
          'international-travel-link': (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a href="#past" className="underline text-blue-500" />
          ),
          paragraph: <p className="mt-3" />,
        }}
      />
    ),
  },
  {
    question: 'camp67-faq.tours-packages',
    answer: <Trans i18nKey={'camp67-faq.tours-packages.answer' satisfies MessageKey} />,
  },
  {
    question: 'camp67-faq.kumbh-sukrit',
    answer: (
      <FormattedString
        message="camp67-faq.kumbh-sukrit.answer"
        components={{
          'kumbh-sukrit-link': (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://kumbhsukrit.com"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            />
          ),
          paragraph: <p className="mt-3" />,
          'swt-link': (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href="https://sukritwellness.com"
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500"
            />
          ),
        }}
      />
    ),
  },
  {
    question: 'camp67-faq.all-day-facilities',
    answer: <FormattedString message="camp67-faq.all-day-facilities.answer" />,
  },
];

export const FAQ: React.FC<FAQItem> = ({ question: questionKey, answer }) => {
  const answerRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const getMessage = useMessages();
  const question = getMessage(questionKey);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <details
      open={isOpen}
      onClick={(e) => {
        const element = e.target as HTMLElement;
        if (!(element.contains(answerRef.current) || element.tagName === 'A')) {
          e.preventDefault();
          if (isOpen === false) {
            // If it's false, it's about to be opened.
            analyticsState.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
              buttonSource: ButtonTag.OPEN_FAQ,
              question,
            });
          }
          setIsOpen((prev) => !prev);
        }
      }}
      className="py-4 px-6 font-bold cursor-pointer select-none border-2 border-[#A1F6A5] rounded-md"
    >
      <summary className="flex flex-row items-center justify-between font-semibold">
        <span>{question}</span>
        <Sprite
          name="down"
          width="56"
          height="30"
          className={clsx(
            'w-4 h-2 min-w-4 min-h-2 md:min-w-5 md:min-h-3 md:w-5 md:h-3 transition',
            {
              'rotate-180': isOpen,
            },
          )}
          stroke="black"
          strokeWidth={6}
        />
      </summary>
      <div ref={answerRef} className="mt-5 font-normal">
        {answer}
      </div>
    </details>
  );
};
