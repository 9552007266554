import React, { useEffect, useState } from 'react';
import { TentTypeV1 } from '@camp67/model';
import { Modal } from '../policy-modal';
import { tents } from '../../constants/tents';
import { ModalCloseButton } from './modal-close-button';
import { TentExperienceCarousel } from './tent-experience-carousel';
import { TentExperienceGalleryItem } from './tent-experience-gallery';

interface TentExperienceModalProps {
  tentType: TentTypeV1;
  onClose: VoidFunction;
}

export const TentExperienceModalV2: React.FC<TentExperienceModalProps> = ({
  tentType,
  onClose,
}) => {
  const [type, setType] = useState<TentTypeV1>(tentType);
  const { gallery } = tents[type];

  useEffect(() => {
    const bodyWidth = document.body.clientWidth;
    document.body.style.overflow = 'hidden';
    document.body.style.width = `${bodyWidth}px`;

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.width = 'auto';
    };
  }, [tentType]);

  useEffect(() => {
    if (gallery) {
      // Simulates loading images beforehand so that the gallery experience is quick
      for (const item of gallery) {
        const i = new Image();
        i.src = item.imageUrl;
      }
    }
  }, [gallery]);

  return (
    <Modal isOpen={!!type} onClose={onClose}>
      <div
        className="bg-white w-[95%] md:w-8/12 lg:w-7/12 h-[95%] z-20 relative m-auto flex flex-col items-center justify-start rounded-2xl"
        id="tent-experience-modal-content"
      >
        <ModalCloseButton onClose={onClose} />
        <div className="overflow-y-auto w-full relative mb-6">
          <>
            <TentExperienceCarousel tent={type} onTentChange={(t) => setType(t)} />
            <TentExperienceGalleryItem tentType={type} />
          </>
        </div>
      </div>
    </Modal>
  );
};
