import { useCallback, useEffect, useState } from 'react';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const listener = useCallback(() => {
    setIsMobile(window.innerWidth <= 840);
  }, []);

  useEffect(() => {
    listener();

    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [listener]);

  return isMobile;
};
