import { Parallax } from 'react-scroll-parallax';
import { Section } from '../components/section';
import { sections } from '../hooks/use-section-analytics';
import Sprite from '../sprites/sprite';
import { FormattedString, useMessages } from '../i18n/useMessages';

export const LocationSection = () => {
  const getMessage = useMessages();
  const gangesBanks = getMessage('camp67-our-location.ganges-banks');
  const gangesBanksLocation = getMessage('camp67-our-location.ganges-banks-location');

  return (
    <Section className="w-full m-auto mt-22 z-0" id={sections['locationGanges']}>
      <div className="mx-10 flex flex-col md:flex-row md:items-center md:justify-between md:mx-20 lg:mx-[10%]">
        <h3 className="font-display text-center text-2xl leading-5 md:text-left md:text-4xl md:leading-6 xl:text-5xl xl:leading-8 text-red-200">
          {gangesBanks}
          <br />
          <FormattedString message="camp67-our-location.lay-tents" />
        </h3>
        <Sprite
          width="238"
          height="221"
          name="fancyf"
          className="fill-red-200 hidden md:w-40 lg:w-max md:block"
        />
      </div>
      <p className="mt-6 mx-auto px-6 w-full max-w-120 text-center text-lg md:text-left md:mt-0 md:px-0 md:mx-20 lg:mx-[10%] xl:mt-6 xl:text-xl md:w-120 leading-snug font-sans">
        {gangesBanksLocation}
      </p>
      <div className="relative overflow-hidden">
        <div className="bg-home-image-top-wave bg-no-repeat w-lvw bg-cover h-48 xl:h-56 absolute -top-1 z-10" />
        <div className="bg-home-image-bottom-wave bg-no-repeat w-lvw bg-cover h-48 xl:h-56 absolute -bottom-1 rotate-180 z-10" />
        <Parallax speed={-45} className="-z-10">
          <video
            src="/bg-video-compressed.mp4"
            autoPlay={true}
            muted={true}
            loop={true}
            controls={false}
            playsInline={true}
            className="h-[800px] md:h-[1000px] w-full object-cover select-none pointer-events-none"
          />
        </Parallax>
      </div>
    </Section>
  );
};
