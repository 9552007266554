import { useAudioVisualiser } from '../hooks/use-audio-visualiser';
import { Section } from '../components/section';
import { sections } from '../hooks/use-section-analytics';
import { useIsMobile } from '../hooks/use-is-mobile';
import { useCallback } from 'react';
import { AmplitudeEvent, ButtonTag, analyticsState } from '../state/amplitude';
import { images } from '../constants/images';
import Sprite from '../sprites/sprite';
import { FormattedString, useMessages } from '../i18n/useMessages';

const experiences = [
  images['experience-1'],
  images['experience-2'],
  images['experience-3'],
  images['experience-4'],
];

export const ExperienceKumbhSection = () => {
  const { isPlaying, handleAudioPlay, handleAudioEnd, canvasRef } =
    useAudioVisualiser('/kumbh.mp3');

  const isMobile = useIsMobile();

  const onClick = useCallback(() => {
    if (!isPlaying) {
      handleAudioPlay();

      analyticsState?.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
        buttonTag: ButtonTag.HEAR_SOUNDS_OF_KUMBH,
      });
    } else {
      handleAudioEnd();

      analyticsState?.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
        buttonTag: ButtonTag.PAUSE_SOUNDS_OF_KUMBH,
      });
    }
  }, [handleAudioEnd, handleAudioPlay, isPlaying]);

  const getMessage = useMessages();

  const soundsOfKumbhMobile = getMessage('camp67-experience-kumbh.sound.mobile');
  const hearSoundsOfKumbh = getMessage('camp67-experience-kumbh.play-sound');
  const pauseSoundsOfKumbh = getMessage('camp67-experience-kumbh.pause-sound');

  return (
    <Section className="md:mt-16 relative" id={sections['experienceKumbh']}>
      <div className="relative flex flex-col items-center gap-0">
        <div className="relative text-center lg:text-left w-full px-6 lg:absolute lg:w-max lg:left-10 lg:top-1/2 lg:-translate-y-1/2 lg:bg-green-50 rounded-md lg:px-16 py-20 h-min">
          <hgroup>
            <h2 className="text-sm font-sans uppercase text-gray-600 font-bold tracking-widest xl:text-base">
              {getMessage('camp67-experience-kumbh.the-kumbh')}
            </h2>
            <h3 className="text-2xl leading-5 md:text-4xl md:leading-6 xl:text-5xl xl:leading-8 text-green-300 font-display mt-4 md:mt-9">
              <FormattedString message='camp67-experience-kumbh.engage-unique-experience' />
            </h3>
          </hgroup>
          <p className="m-auto w-full max-w-120 lg:m-[unset] lg:mt-6 xl:max-w-130 mt-6 text-lg xl:text-xl px-4 sm:px-0">
            {getMessage('camp67-experience-kumbh.sadhus')}
          </p>
          <button
            onClick={onClick}
            className="m-auto mt-8 md:mt-14 lg:mx-0 lg:mr-auto bg-gray-200 px-6 py-4 flex flex-row items-center gap-3 rounded-md fill-white cursor-pointer z-10 select-none hover:bg-gray-400 transition-all duration-300 ease-in-out"
          >
            {!isPlaying ? (
              <Sprite name="unmute" width="16" height="12" />
            ) : (
              <Sprite name="mute" width="16" height="12" />
            )}
            <span className="text-white uppercase tracking-widest text-sm xl:text-base">
              {!isPlaying ? (
                <strong>{isMobile ? soundsOfKumbhMobile : hearSoundsOfKumbh}</strong>
              ) : (
                <strong>{isMobile ? soundsOfKumbhMobile : pauseSoundsOfKumbh}</strong>
              )}
            </span>
          </button>
          <canvas
            className="absolute bottom-0 left-0 w-full h-36 -z-10"
            ref={canvasRef}
          />
        </div>
        <div className="flex flex-col px-6 gap-8 w-full md:px-0 md:gap-0 md:grid md:grid-cols-[0.85fr_1.15fr] md:items-end">
          <img
            loading="lazy"
            src={experiences[0]}
            alt={getMessage('camp67-experience-kumbh.naga-sadhu.image-alt')}
            className="max-h-96 md:max-h-none w-full object-cover object-[0%_20%] select-none pointer-events-none"
          />
          <img
            loading="lazy"
            src={experiences[1]}
            alt={getMessage('camp67-experience-kumbh.naga-sadhu-orange.image-alt')}
            className="max-h-96 md:max-h-none w-full object-cover select-none pointer-events-none"
          />
        </div>
        <div className="flex flex-col px-6 gap-8 mt-8 w-full md:mt-0 md:px-0 md:gap-0 md:grid md:grid-cols-[1.05fr_0.95fr] md:items-start">
          <img
            loading="lazy"
            src={experiences[2]}
            alt={getMessage('camp67-experience-kumbh.naga-sadhu-beard.image-alt')}
            className="max-h-96 md:max-h-none w-full object-cover select-none pointer-events-none"
          />
          <img
            loading="lazy"
            src={experiences[3]}
            alt={getMessage('camp67-experience-kumbh.canoe.image-alt')}
            className="max-h-96 md:max-h-none w-full object-cover select-none pointer-events-none"
          />
        </div>
      </div>
    </Section>
  );
};
