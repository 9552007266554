import Typed from 'typed.js';

export class Typewriter {
  writers: Map<string, Typed> = new Map();

  add(element: string, words: string[]) {
    if (this.writers.has(element)) {
      return;
    }

    const typed = new Typed(element, {
      strings: words,
      typeSpeed: 100,
      backSpeed: 100,
      startDelay: 100,
      loop: true,
      showCursor: false,
    });

    this.writers.set(element, typed);
  }

  destroy() {
    this.writers.forEach((t) => t.destroy());
  }
}

export const typewriter = new Typewriter();
