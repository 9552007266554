import { useRef, useState, useEffect, useCallback } from 'react';

let animationController;

export const useAudioVisualiser = (audioSrc: string) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    setAudio(new Audio(audioSrc));
  }, [audioSrc]);

  const source = useRef<MediaElementAudioSourceNode>();
  const analyser = useRef<AnalyserNode>();

  const [isPlaying, setIsPlaying] = useState(false);

  const handleAudioPlay = () => {
    if (!audio) return;
    audio.volume = 0.05;
    audio.play();
    setIsPlaying(true);

    const audioContext = new AudioContext();
    if (!source.current) {
      source.current = audioContext.createMediaElementSource(audio);
      analyser.current = audioContext.createAnalyser();
      source.current.connect(analyser.current);
      analyser.current.connect(audioContext.destination);
    }
    visualiseData();
  };

  const handleAudioEnd = useCallback(() => {
    if (!audio) return;
    audio.pause();
    setIsPlaying(false);

    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) return;
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  }, [audio, canvasRef]);

  const visualiseData = () => {
    if (canvasRef.current && analyser.current && audio) {
      animationController = window.requestAnimationFrame(visualiseData);
      if (audio.paused) {
        return cancelAnimationFrame(animationController);
      }

      const bufferSize = 160;
      const songData = new Uint8Array(bufferSize);
      analyser.current.getByteFrequencyData(songData);

      const ctx = canvasRef.current.getContext('2d');
      if (!ctx) return;
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      const bar_width = 1;
      let start = 0;
      for (let i = 0; i < songData.length; i++) {
        start = i * 2;
        ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
        ctx.fillRect(start, canvasRef.current.height, bar_width, -songData[i]);
      }
    }
  };

  return { isPlaying, handleAudioPlay, handleAudioEnd, canvasRef } as const;
};
